<template>
  <layout-vertical>

    <router-view />
    <ociBrandSideMenu
      slot="vertical-menu-header"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    />
    <div slot="breadcrumb" />
    <!-- <ociBreadcrumb slot="breadcrumb" /> -->
    <template
      #navbar="{ toggleVerticalMenuActive }"
    >
      <!-- :style="getHideNavbarCss" -->
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
    <ociFooter slot="footer" />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import ociBrandSideMenu from '@/layouts/components/ociBrandSideMenu.vue'
import ociBreadcrumb from '@/layouts/components/ociBreadcrumb.vue'
import ociFooter from '@/layouts/components/ociFooter.vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    ociBrandSideMenu,
    ociBreadcrumb,
    ociFooter,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  computed: {
    getHideNavbarCss() {
      // if ($route.meta.pageTitle === 'Map') return 'display:none; height:0;'

      return ''
    },
  },
  setup() {
    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
    } = useAppConfig()

    // Vertical Menu
    const {
      isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    return {
      toggleVerticalMenuActive,
    }
  },
}
</script>
