<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }} | {{version}} |
      <b-link
        class="ml-25"
        href="https://www.promentum.co.uk"
        target="_blank"
      >Promentum Ltd</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { version } from '../../../package.json'

export default {
  name: 'OciFooter',  
  components: {
    BLink,
  },
  setup() {
    return {
      version,
    }
  },
}
</script>
